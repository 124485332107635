import { escapeRegex, join } from "../../utils";
export var MOSCOW_FIAS = '0c5b2444-70a0-4932-980c-b4dc0d3f02b5';
export var ST_PETERSBURG_FIAS = 'c2deb16a-0330-4f05-821f-1d09c93331e6';
export function isCityArea(suggestion) {
    var _a;
    return !!((_a = suggestion.data.settlement_type) === null || _a === void 0 ? void 0 : _a.match(/^(тер|гск|промзона|мкр|кв-л)/)) || !suggestion.data.postal_code;
}
export function formatCityResults(_formatted, value, suggestion) {
    var regExp = new RegExp("(^|\\s|>|-)(".concat(escapeRegex(value).trim().split(/[\s,]+/).filter(function (el) { return el.length; }).join('|'), ")"), 'igu');
    var name;
    if (suggestion.data.settlement) {
        name = join([
            "<strong>".concat(join([suggestion.data.settlement_type_full, suggestion.data.settlement]), "</strong>"),
            suggestion.data.city
        ], ', ');
    }
    else {
        name = "<strong>".concat(join([suggestion.data.city_type === 'г' ? null : suggestion.data.city_type_full, suggestion.data.city]), "</strong>");
    }
    suggestion.value = formatSelectedCity(suggestion);
    var area = suggestion.data.area_with_type;
    if (suggestion.data.area_with_type === suggestion.data.city_with_type || suggestion.data.area_with_type === suggestion.data.settlement_with_type) {
        area = null;
    }
    return join([name, area, formatRegion(suggestion.data, false)], ', ')
        .replace(regExp, "$1<mark>$2</mark>");
}
export function formatSelectedCity(suggestion, withRegion, withCityType) {
    var _a, _b;
    if (withRegion === void 0) { withRegion = true; }
    if (withCityType === void 0) { withCityType = false; }
    var city;
    if (suggestion.data.settlement && suggestion.data.settlement !== suggestion.data.city_district) {
        city = join([suggestion.data.settlement_type, suggestion.data.settlement]);
    }
    else {
        city = (suggestion.data.city_type !== 'г' || withCityType) ? (_a = suggestion.data.city_with_type) === null || _a === void 0 ? void 0 : _a.trim() : (_b = suggestion.data.city) === null || _b === void 0 ? void 0 : _b.trim();
    }
    if (!withRegion) {
        return city;
    }
    var region = formatRegion(suggestion.data, true);
    return join([city, region ? "(".concat(region, ")") : null]);
}
export function formatAddress(suggestion) {
    var address = suggestion.data;
    return join([
        address.settlement && (address.settlement === address.city_district) ? join([address.settlement_type, address.settlement]) : '',
        join([address.street_type, address.street]),
        join([address.house_type, address.house, address.block_type, address.block]),
        join([address.flat_type, address.flat]),
    ], ', ');
}
function formatRegion(address, addArea) {
    var region = address.region_with_type;
    if (address.region_type === 'Респ' || address.region_type === 'обл') {
        region = wordBreakReplace(region, address.region_type, address.region_type_full);
    }
    if (address.region_type === 'г') {
        region = address.region;
    }
    if (addArea && +address.capital_marker < 1 && address.area && address.area_with_type !== address.city_with_type) {
        region = join([wordBreakReplace(address.area_with_type, address.area_type, address.area_type_full), region], ', ');
    }
    if (address.city === address.region && !(address.settlement && address.settlement !== address.city_district)) {
        region = null;
    }
    return region;
}
function wordBreakReplace(subject, search, replace) {
    var regExp = new RegExp("([^\u0430-\u044F]|^)".concat(escapeRegex(search), "(?=[^\u0430-\u044F]|$)"), 'ui');
    return subject.replace(regExp, "$1".concat(replace));
}
