var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { isFullyVisible } from "../utils";
var ITEM_SELECTOR = '.search-result__item';
var SearchResultKeys = /** @class */ (function () {
    function SearchResultKeys(input, results, itemSelector, onSelect) {
        if (itemSelector === void 0) { itemSelector = ITEM_SELECTOR; }
        this.results = results;
        this.itemSelector = itemSelector;
        this.onSelect = onSelect;
        input.addEventListener("keyup", this.isHandledKeyPressed.bind(this));
        input.addEventListener("keypress", this.isHandledKeyPressed.bind(this));
        input.addEventListener("keydown", this.onKeyPress.bind(this));
    }
    SearchResultKeys.prototype.isHandledKeyPressed = function (event) {
        if (!['ArrowDown', 'ArrowUp', 'Home', 'End', 'PageUp', 'PageDown', 'Enter', 'Escape'].includes(event.key)) {
            return false;
        }
        if (event.key === 'Enter') {
            if (this.getSelectedItem()) {
                event.preventDefault();
                event.stopPropagation();
            }
            if (event.type === 'keyup') {
                this.onSelect(this.count() === 1 ? this.getFirstItem() : this.getSelectedItem());
            }
            return true;
        }
        if (event.type === 'keydown') {
            this.validateSelection();
        }
        if (event.shiftKey || event.altKey || event.ctrlKey || event.metaKey) {
            return false;
        }
        if (this.isEmpty()) {
            return false;
        }
        event.preventDefault();
        event.stopPropagation();
        return true;
    };
    SearchResultKeys.prototype.onKeyPress = function (event) {
        if (!this.isHandledKeyPressed(event)) {
            this.deselect();
            if (this.results.firstElementChild.scrollTop) {
                this.results.firstElementChild.scrollTo({ top: 0, behavior: 'auto' });
            }
            return;
        }
        this.select(this.parseKey(this.getSelectedItem(), event.key));
    };
    SearchResultKeys.prototype.parseKey = function (item, key) {
        var _a, _b;
        switch (key) {
            case 'ArrowDown':
                return (_a = this.getNextItem()) !== null && _a !== void 0 ? _a : this.getLastItem();
            case 'ArrowUp':
                return (_b = this.getPreviousItem()) !== null && _b !== void 0 ? _b : this.getFirstItem();
            case 'Home':
                return this.getFirstItem();
            case 'End':
                return this.getLastItem();
            case 'PageUp':
                return this.multistep(item !== null && item !== void 0 ? item : this.getLastItem(), this.calculateNumberOfLines(), true);
            case 'PageDown':
                return this.multistep(item !== null && item !== void 0 ? item : this.getFirstItem(), this.calculateNumberOfLines());
            case 'Enter':
                return this.getSelectedItem();
        }
    };
    SearchResultKeys.prototype.getSelectedItem = function () {
        return this.results.querySelector("".concat(this.itemSelector, "[data-selected]"));
    };
    SearchResultKeys.prototype.isEmpty = function () {
        return !this.getFirstItem();
    };
    SearchResultKeys.prototype.getFirstItem = function () {
        return this.results.querySelector(this.itemSelector);
    };
    SearchResultKeys.prototype.getLastItem = function () {
        return __spreadArray([], __read(this.results.querySelectorAll(this.itemSelector)), false).pop();
    };
    SearchResultKeys.prototype.getSelectedItems = function () {
        return this.results.querySelectorAll("".concat(this.itemSelector, "[data-selected]"));
    };
    SearchResultKeys.prototype.calculateNumberOfLines = function () {
        var item = this.getFirstItem();
        if (!item) {
            return 0;
        }
        return Math.floor(this.results.getBoundingClientRect().height / item.getBoundingClientRect().height);
    };
    SearchResultKeys.prototype.select = function (item) {
        this.lastSelected = item;
        if (!item) {
            return false;
        }
        this.deselect(item);
        item.dataset.selected = '';
        this.scrollIntoView(item);
        return true;
    };
    SearchResultKeys.prototype.multistep = function (start, steps, reverse) {
        var e_1, _a;
        if (reverse === void 0) { reverse = false; }
        var items = Array.from(this.results.querySelectorAll(this.itemSelector));
        var current;
        if (reverse) {
            items = items.reverse();
        }
        try {
            for (var items_1 = __values(items), items_1_1 = items_1.next(); !items_1_1.done; items_1_1 = items_1.next()) {
                var item = items_1_1.value;
                if (!current) {
                    if (item === start) {
                        current = item;
                    }
                    continue;
                }
                if (steps--) {
                    current = item;
                }
                else {
                    break;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (items_1_1 && !items_1_1.done && (_a = items_1.return)) _a.call(items_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return current !== null && current !== void 0 ? current : start;
    };
    SearchResultKeys.prototype.scrollIntoView = function (item) {
        if (isFullyVisible(item, this.results)) {
            return;
        }
        var itemRect = item.getBoundingClientRect();
        var wrapRect = this.results.getBoundingClientRect();
        var diff = itemRect.bottom > wrapRect.bottom
            ? itemRect.bottom - wrapRect.bottom + itemRect.height * 0.4
            : itemRect.top - wrapRect.top - itemRect.height * 0.4;
        this.results.firstElementChild.scrollBy({
            top: diff,
            behavior: Math.abs(diff) > itemRect.height * 1.5 ? 'smooth' : 'auto',
        });
    };
    SearchResultKeys.prototype.deselect = function (exclude) {
        var deselected = false;
        this.getSelectedItems().forEach(function (item) {
            if (item !== exclude) {
                delete item.dataset.selected;
            }
            else {
                deselected = true;
            }
        });
        return deselected;
    };
    SearchResultKeys.prototype.getNextItem = function () {
        var e_2, _a;
        var selected = this.getSelectedItem();
        if (!selected) {
            return this.getFirstItem();
        }
        var selectedItemFound = false;
        try {
            for (var _b = __values(this.results.querySelectorAll(this.itemSelector)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var element = _c.value;
                if (selectedItemFound) {
                    return element;
                }
                if (element === selected) {
                    selectedItemFound = true;
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return selected ? null : this.getFirstItem();
    };
    SearchResultKeys.prototype.getPreviousItem = function () {
        var selected = this.getSelectedItem();
        return selected ? selected.previousElementSibling : this.getLastItem();
    };
    SearchResultKeys.prototype.count = function () {
        return this.results.querySelectorAll(this.itemSelector).length;
    };
    SearchResultKeys.prototype.validateSelection = function () {
        this.deselect(this.lastSelected);
        if (this.lastSelected) {
            this.scrollIntoView(this.lastSelected);
        }
    };
    return SearchResultKeys;
}());
export default SearchResultKeys;
