import { isFullyVisible, isMobile } from "../utils";
import { StatusClassNames } from "../types/StatusClassNames";
export function useDragToScroll(wrap) {
    var element = wrap.firstElementChild;
    if (!isMobile) {
        element.addEventListener('mousedown', mouseDownEvent);
    }
    function destroy() {
        element.removeEventListener('mousedown', mouseDownEvent);
    }
    function isDragging() {
        return dragging;
    }
    // private
    var dragging = false;
    var startPosition = 0;
    var startScrollPosition = 0;
    var clickTarget;
    function mouseDownEvent(event) {
        if (isFullyVisible(element, wrap, false)) {
            return;
        }
        initDrag();
        startPosition = event.x;
        startScrollPosition = wrap.scrollLeft;
        event.preventDefault();
        clickTarget = event.target;
        clickTarget.addEventListener('click', mouseUpHandler, { once: true });
        return false;
    }
    function mouseMoveHandler(event) {
        var offset = startPosition - event.x;
        if (!dragging && Math.abs(offset) > 4) {
            dragging = true;
            document.documentElement.classList.add(StatusClassNames.dragging);
        }
        if (dragging) {
            wrap.scrollLeft = startScrollPosition + offset;
        }
    }
    function mouseUpHandler(event) {
        if (dragging) {
            event.preventDefault();
            event.stopPropagation();
            finishDrag();
        }
    }
    function initDrag() {
        document.addEventListener('click', finishDrag);
        document.addEventListener('mousemove', mouseMoveHandler);
    }
    function finishDrag() {
        if (dragging) {
            dragging = false;
        }
        document.documentElement.classList.remove(StatusClassNames.dragging);
        document.removeEventListener('click', finishDrag);
        document.removeEventListener('mousemove', mouseMoveHandler);
        clickTarget === null || clickTarget === void 0 ? void 0 : clickTarget.removeEventListener('click', mouseUpHandler);
    }
    return { destroy: destroy, isDragging: isDragging };
}
